<template>
  <div>
    <div
      class="bg-[url('~/assets/images/events/background.png')] bg-cover bg-top bg-no-repeat text-white text-sm"
    >
      <div class="px-4 lg:px-16 py-10">
        <img
          src="~/assets/images/asifa-bronze-logo.svg"
          alt="ASIFA Hollywood"
          class="h-20 w-auto mb-4"
        >
        <h3 class="text-yellow-asifa font-semibold tracking-widest">
          SPECIAL SCREENING OF
        </h3>
        <slot />
      </div>
    </div>
    <div class="bg-[#2D2D2D] text-center text-white px-4 lg:px-24 py-10">
      <h3 class="font-semibold">
        Corporate Members
      </h3>
      <div class="py-10 flex flex-col gap-6">
        <div class="flex flex-wrap justify-center gap-6 items-center">
          <img
            src="~/assets/images/events/sponsors/apple.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/disney.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/illum.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/laika.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/lukasfilm2.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
        </div>
        <div class="flex flex-wrap justify-center gap-6 items-center">
          <img
            src="~/assets/images/events/sponsors/netflix.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/nic.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/pixar.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/sony.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/wb.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/weta.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
        </div>
      </div>

      <h3 class="font-semibold">
        Annie Awards Sponsor
      </h3>
      <div class="py-10 flex flex-col gap-6">
        <div class="flex flex-wrap justify-center gap-6">
          <img
            src="~/assets/images/events/sponsors/dw.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/netflix.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/nic.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/waltdis.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/pixar.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
        </div>
        <div class="flex flex-wrap justify-center gap-4">
          <img
            src="~/assets/images/events/sponsors/disney.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/illum.png"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/sony.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
          <img
            src="~/assets/images/events/sponsors/sky.svg"
            alt="sponsor"
            class="max-h-12 max-w-[100px]"
          >
        </div>
      </div>
    </div>
  </div>
</template>
